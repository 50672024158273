



























import { Vue, Component } from 'vue-property-decorator';
import { downloadJson } from '@/utils';
import { SkillMatrix, User } from '../models';
import { skillsService } from '../services';
// import Sessions from '../components/Sessions.vue';

@Component({ components: {} })
export default class Dashboard extends Vue {
  config = {};

  get user(): User {
    return this.$store.getters['user/user'];
  }
  get tiles(): boolean {
    return this.$store.getters['skills/tiles'];
  }
  get viewIcon(): string {
    return this.tiles ? 'mdi-view-list' : 'mdi-view-grid';
  }

  created() {
    // Event listeners
    this.$bus.$off('skillmatrix-clone').$on('skillmatrix-clone', this.onSkillMatrixClone);
    this.$bus.$off('skillmatrix-export').$on('skillmatrix-export', this.onSkillMatrixExport);
    this.$bus.$off('skillmatrix-delete').$on('skillmatrix-delete', this.onSkillMatrixDelete);
  }

  mounted() {
    this.$bus.$emit('title-change', 'Retro', '/retro');
    document.title = 'AgileMate Skills';
    //this.$analytics.setCurrentScreen(document.title);
    this.$analytics.logEvent('skills-dashboard');
    this.$bind('config', skillsService.configRef);
  }

  toggleView() {
    this.$store.dispatch('retro/toggleTiles');
  }

  // Event handlers
  async onSkillMatrixClone(matrix: SkillMatrix) {
    try {
      // await skillsService.cloneSkillMatrix(matrix, this.user);
      this.$bus.$emit('snackbar-notify', 'Skill Matrix successfully cloned', 'success');
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  async onSkillMatrixExport(matrix: SkillMatrix) {
    try {
      downloadJson(matrix, matrix.id);
      this.$bus.$emit('snackbar-notify', 'Skill Matrix successfully exported', 'success');
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  async onSkillMatrixDelete(matrix: SkillMatrix) {
    try {
      await skillsService.deleteSkillMatrix(matrix);
      this.$bus.$emit('snackbar-notify', 'Skill Matrix successfully deleted', 'success');
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
}
