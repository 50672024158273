import { authGuard } from '@/utils';

const Dashboard = () => import(/* webpackChunkName: "skills" */ './views/Dashboard.vue');
const SkillCreate = () => import(/* webpackChunkName: "skills" */ './views/SkillCreate.vue');
const SkillEdit = () => import(/* webpackChunkName: "skills" */ './views/SkillEdit.vue');
const SkillView = () => import(/* webpackChunkName: "skills" */ './views/SkillView.vue');

export default [
  {
    path: '/skills',
    alias: '/sk',
    name: 'skills-list',
    component: Dashboard,
    beforeEnter: authGuard,
  },
  {
    path: '/skills/create',
    alias: '/sk/c',
    name: 'skills-create',
    component: SkillCreate,
    beforeEnter: authGuard,
  },
  {
    path: '/skills/:id/edit',
    alias: '/sk/:id/e',
    name: 'skills-edit',
    props: true,
    component: SkillEdit,
    beforeEnter: authGuard,
  },
  {
    path: '/skills/:id',
    alias: '/sk/:id',
    name: 'skills-view',
    props: true,
    component: SkillView,
    beforeEnter: authGuard,
  },
];
