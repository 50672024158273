import type { User, Participant as TeamMember } from '@/models';

export type TeamMembers = { [key: string]: TeamMember };

export { User, TeamMember };

export interface Skill {
  id: string;
  name: string;
  category: string;
}
export type Skills = { [key: string]: Skill };

export type SkillRequirements = { [key: string]: number };

export interface SkillLevel {
  // memberId: string;
  currentLevel: number;
  targetLevel?: number;
}
export type SkillLevels = { [key: string]: SkillLevel };

export const skillMatrixStatuses = {
  DRAFT: { name: 'Draft', color: 'warning' },
  ACTIVE: { name: 'Active', color: 'success' },
  CLOSED: { name: 'Closed', color: 'error' },
};
export type SkillMatrixStatus = typeof skillMatrixStatuses.DRAFT;

export const skillMatrixTypes = {
  TEAM: { name: 'Team matrix' },
  ROLE: { name: 'Role matrix' },
  PRODUCT: { name: 'Product matrix' },
  DEPARTMENT: { name: 'Department matrix' },
  ORGANIZATION: { name: 'Organization matrix' },
};
export type SkillMatrixType = typeof skillMatrixTypes.PRODUCT;

// export const skillCategories = {
//   CORE: { name: 'Core' },
//   FUNC: { name: 'Functional' },
//   TECH: { name: 'Technical' },
//   LEAD: { name: 'Leadership' },
//   CERT: { name: 'Certification' },
// };
// export type SkillCategory = typeof skillCategories.CORE;

export const reviewTypes = {
  SELF: { name: 'Self Assessment' },
  PEER: { name: 'Peer Review' },
  MANAGER: { name: 'Manager Feedback' },
};
export type ReviewType = typeof reviewTypes.SELF;

export interface Rating {
  id: string;
  name: string;
  description: string;
  color: string;
}
export type Ratings = Rating[];

export interface RatingScale {
  name: string;
  description: string;
  icon: string;
  levels: Ratings;
}
export type RatingScales = { [key: string]: RatingScale };

export interface SkillAssessment {
  id: string;
  type: ReviewType;
  date: number;
  reviewer: TeamMember;
  reviewee: TeamMember;
  skills: SkillLevels;
}

export interface SkillMatrixConfig {
  skillCategories: string[];
  proficiencyScale: RatingScale;
  importanceScale: RatingScale;
  interestScale: RatingScale;
}

export interface SkillMatrix {
  id: string;
  title: string;
  description: string;
  background: string;
  type: SkillMatrixType;
  status: SkillMatrixStatus;
  date: number;
  // owner: User;
  skills: Skills;
  people: TeamMembers;
  requirements: SkillRequirements;
  assessments: SkillAssessment[];
  config: SkillMatrixConfig;
  owner: string;
  createdOn?: number;
  updatedOn?: number | null;
}
export type SkillMatrices = SkillMatrix[];
