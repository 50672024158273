




















































































































































































































































import { Vue, Component, PropSync, Watch } from 'vue-property-decorator';
import { SkillMatrix, skillMatrixStatuses, skillMatrixTypes, User } from '../models';
import { skillsService } from '../services';

@Component({ components: {} })
export default class SkillMatrixBuilder extends Vue {
  @PropSync('value', { type: Object as () => SkillMatrix, required: true })
  matrix!: SkillMatrix;

  skillsService = skillsService;
  skillMatrixTypes = skillMatrixTypes;
  config = {};
  valid = false;

  // get userId(): string {
  //   return this.$store.getters['user/userId'];
  // }
  // get user(): User {
  //   return this.$store.getters['user/user'];
  // }
  get skillMatrixExists(): boolean {
    return this.matrix.createdOn !== undefined;
  }

  @Watch('session.title')
  onTitleChange(title) {
    document.title = title;
  }

  created() {
    this.init();
  }

  async init() {
    // await this.$bind('config', retrospectiveService.getConfigRef());
    // await this.$bind('cards', retrospectiveService.getCardsRef(this.session.id));
    // await this.$bind('columns', retrospectiveService.getColumnsRef(this.session.id));
    //this.$bus.$emit('loading-indicator', false);
  }

  saveSkillMatrix() {
    this.$emit('save', this.matrix);
  }

  deleteSkillMatrix() {
    this.$emit('delete', this.matrix);
  }

  setSkillMatrixStatus(status: string) {
    if (status === 'Active') {
      this.matrix.status = skillMatrixStatuses.ACTIVE;
    } else if (status === 'Closed') {
      this.matrix.status = skillMatrixStatuses.CLOSED;
    }
    this.saveSkillMatrix();
  }
}
