import { db, firestore } from '@/firebase';
import { SkillMatrix, skillMatrixStatuses, skillMatrixTypes, RatingScales } from './models';
import { getId } from '@/utils';

export const proficiencyRatingScales: RatingScales = {
  SKILL_LEVELS: {
    name: 'Skill Levels',
    description: '',
    icon: 'mdi-account-star',
    levels: [
      { id: '0', name: 'No Skill', description: 'I have no experience with this skill', color: 'red' },
      { id: '1', name: 'Basic', description: 'I know some basics but require support for this skill', color: 'yellow' },
      {
        id: '2',
        name: 'Intermediate',
        description: 'I have enough knowledge and can work independently with this skill',
        color: 'light-green',
      },
      {
        id: '3',
        name: 'Advanced',
        description: 'I have deep knowledge and can mentor others with this skill',
        color: 'green darken-2',
      },
    ],
  },
  AWARENESS_LEVELS: {
    name: 'Awareness Levels',
    description: '',
    icon: 'mdi-account-star',
    levels: [
      { id: '0', name: 'Unaware', description: 'I am unaware of this skill', color: 'red' },
      {
        id: '1',
        name: 'Awareness',
        description: 'I am aware of this skill but have no hands-on experience',
        color: 'orange',
      },
      {
        id: '2',
        name: 'Novice',
        description: 'I have some minimal hands-on experience but require support for this skill',
        color: 'yellow',
      },
      {
        id: '3',
        name: 'Professional',
        description: 'I have sufficient hands-on experience and can work independently with this skill',
        color: 'light-green',
      },
      {
        id: '4',
        name: 'Expert',
        description: 'I have extensive experience and can mentor others with this skill',
        color: 'green darken-2',
      },
    ],
  },
  DREYFUS_MODEL: {
    name: 'Dreyfus Model',
    description: '',
    icon: 'mdi-account-star',
    levels: [
      { id: '0', name: 'Novice', description: 'I have no or very little experience in the area', color: 'red' },
      {
        id: '1',
        name: 'Advanced Beginner',
        description: 'I have some experience in the area but require guidance',
        color: 'orange',
      },
      {
        id: '2',
        name: 'Competent',
        description: 'I have moderate experience in the area, and can act based on guidelines and knowledge',
        color: 'yellow',
      },
      {
        id: '3',
        name: 'Proficient',
        description: 'I have significant experience in the area, and can act based on knowledge',
        color: 'light-green',
      },
      {
        id: '4',
        name: 'Expert',
        description: 'I have vast experience in the area, and can act based on knowledge and intuition',
        color: 'green darken-2',
      },
    ],
  },
};
export const defaultProficiencyRatingScale = proficiencyRatingScales.SKILL_LEVELS;

export const importanceRatingScales: RatingScales = {
  DEFAULT: {
    name: 'Importance Levels',
    description: 'Rating scale for assessing skill importance',
    icon: 'mdi-account-alert',
    levels: [
      {
        id: '0',
        name: 'Not Important',
        description: 'This skill has little to no current or foreseeable impact',
        color: 'red',
      },
      {
        id: '1',
        name: 'Minimally Important',
        description: 'This skill has minor impact to the role or team objectives',
        color: 'orange',
      },
      {
        id: '2',
        name: 'Moderately important',
        description: 'This skill is valuable and can contribute to the role or team objectives',
        color: 'yellow',
      },
      {
        id: '3',
        name: 'Important',
        description: 'This skill is very important and regularly needed for the role and team objectives',
        color: 'light-green',
      },
      {
        id: '4',
        name: 'Critical',
        description: 'This skill is essential for the role and team objectives',
        color: 'green darken-2',
      },
    ],
  },
};
export const defaultImportanceRatingScale = importanceRatingScales.DEFAULT;

export const interestRatingScales: RatingScales = {
  DEFAULT: {
    name: 'Interest Levels',
    description: 'Rating scale for assessing upskilling interest',
    icon: 'mdi-account-arrow-up',
    levels: [
      { id: '0', name: 'No Interest', description: 'I have no interest in developing this skill', color: 'red' },
      {
        id: '1',
        name: 'Low Interest',
        description: 'I have little interest in developing this skill',
        color: 'orange',
      },
      { id: '2', name: 'Neutral', description: 'I am neutral towards developing this skill', color: 'yellow' },
      {
        id: '3',
        name: 'Moderate Interest',
        description: 'I am interested in developing this skill',
        color: 'light-green',
      },
      {
        id: '4',
        name: 'High Interest',
        description: 'I am very interested in developing this skill',
        color: 'green darken-2',
      },
    ],
  },
};
export const defaultInterestRatingScales = interestRatingScales.DEFAULT;

export const skillsService = {
  adminRef: db.collection('admin'),
  configRef: db.collection('admin').doc('skills'),
  skillsRef: db.collection('users'),
  proficiencyRatingScales,
  defaultProficiencyRatingScale,
  importanceRatingScales,
  defaultImportanceRatingScale,
  interestRatingScales,
  defaultInterestRatingScales,

  // Admin
  updateAdmin(docId: string, data: firestore.UpdateData) {
    this.adminRef.doc(docId).update(data);
  },
  updateSkills(docId: string, data: firestore.UpdateData) {
    this.skillsRef.doc(docId).update(data);
  },

  // Firestore
  getSkillMatrices(userId: string) {
    return this.skillsRef.where('owner', '==', userId);
  },
  getSkillMatricesByStatus(userId: string, status: string = 'Draft') {
    return this.skillsRef.where('owner', '==', userId).where('status', '==', status);
  },

  createSkillMatrix(matrix: SkillMatrix) {
    matrix.createdOn = Date.now();
    this.skillsRef.doc(matrix.id).update(matrix);
  },
  updateSkillMatrix(matrix: SkillMatrix) {
    matrix.updatedOn = Date.now();
    this.skillsRef.doc(matrix.id).update(matrix);
  },
  deleteSkillMatrix(matrix: SkillMatrix) {
    this.skillsRef.doc(matrix.id).delete();
  },

  // Actions
  getNewSkillMatrix(userId: string): SkillMatrix {
    return {
      id: getId(),
      title: 'Skill Matrix',
      description: '',
      background: '',
      type: skillMatrixTypes.PRODUCT,
      status: skillMatrixStatuses.DRAFT,
      date: Date.now(),
      skills: {},
      people: {},
      requirements: {},
      assessments: [],
      config: {
        skillCategories: [],
        proficiencyScale: defaultProficiencyRatingScale,
        importanceScale: defaultImportanceRatingScale,
        interestScale: defaultInterestRatingScales,
      },
      owner: userId || '',
    };
  },
};
