










import { Vue, Component } from 'vue-property-decorator';
import SkillMatrixBuilder from '../components/SkillMatrixBuilder.vue';
import { SkillMatrix, User } from '../models';
import { skillsService } from '../services';

@Component({ components: { SkillMatrixBuilder } })
export default class SkillCreate extends Vue {
  matrix: SkillMatrix = skillsService.getNewSkillMatrix(this.userId);

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get user(): User {
    return this.$store.getters['user/user'];
  }

  mounted() {
    this.$bus.$emit('title-change', 'Skills', '/skills');
    document.title = 'AgileMate New Skill Matrix';
    //this.$bus.$emit('loading-indicator', true);
  }

  async saveSkillMatrix(matrix: SkillMatrix) {
    try {
      // matrix.createdOn = Date.now();
      // matrix.owner = this.userId;
      await skillsService.createSkillMatrix(matrix);
      this.$bus.$emit('snackbar-notify', 'Skill Matrix successfully saved', 'success');
      this.$analytics.logEvent('skills-create', { matrix_id: matrix.id, user_id: this.userId });
      this.$router.push({ name: 'skills' });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
}
