










import { Vue, Component } from 'vue-property-decorator';

@Component
export default class SkillEdit extends Vue {
  mounted() {
    // this.$bus.$emit('title-change', 'Team', '/team');
  }
}
